<template>
    <div class="container-fluid">
        <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.listadoproveedores') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <proveedores-estados />
                </div>
                <div class="col-md-10">
                        <tabla-datos 
                             :columnas="columnas" 
                             :datos="proveedores"
                             :filtros="filtros" 
                             :filtrosBuscador="filtrosBuscador" 
                             @quieroDatos="obtenerProveedores" 
                             :total="total"
                        ></tabla-datos>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import ProveedoresEstados from './listado/ProveedoresEstados.vue'
import TablaDatos from '../../TablaDatos.vue';
import { PwgsApi } from '../../../services/PwgsApi';
import { FilterMatchMode } from 'primevue/api';
export default {
    components: {
        'proveedores-estados': ProveedoresEstados,
        'tabla-datos': TablaDatos,
    },
    props: {
        id: {
            type: Number,
            default: 0,
        }
    },
data() {
        return {
            proveedores: [],
            eliminado: false,
            parametros: null, // query params de DataTable
            filtrosBuscador: ['nombre_proveedor'], // filtros para el buscador
            filtros: { // filtros de columna
                'nombre_proveedor': { value: '', matchMode: FilterMatchMode.CONTAINS },
            },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'nombre_proveedor',
                    sortable: true,
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_proveedor',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar proveedor ' + id);
                                //this.obtenerUsuario(id);
                                this.$router.push({
                                    name: 'Proveedor',
                                    params: {
                                        id:id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'id_proveedor',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar proveedor ' + id);
                                if (confirm('Desea eliminar el proveedor')) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ],
        }
    },
    methods: {
        async obtenerProveedores(parametros) {
            /* get  modulos/pwgsapi/index.php/proveedores */
            const api = new PwgsApi();
            const params = parametros;
            this.$store.dispatch('cambiarCargando', true);
            const datos = await api.get('proveedores', params);
            this.proveedores = datos.datos;  
            this.total = datos.n_total_registros;  
            this.eliminado = false;
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[1].header = this.$t("general.acciones");
            this.$store.dispatch('cambiarCargando', false);
     
        },
        async eliminar(id) {
            /* delete  modulos/pwgsapi/index.php/proveedores/:id */
            const api = new PwgsApi;
            await api.delete('proveedores/' + id);
            this.obtenerProveedores();
        },
    },
    watch: {
        eliminado() {
            this.obtenerProveedores(this.parametros);
        }
    },
    activated() {
        this.eliminado = false;
        this.obtenerProveedores(this.parametros);
    },
    mounted() {
        this.eliminado = false;
        this.obtenerProveedores(this.parametros);
    },
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>